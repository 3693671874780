import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import SensationDeveloper from "../../../components/developers/sensationdeveloper/SensationDeveloper";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const SensationProjectsPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <SensationDeveloper />
    </>
  )
}

export default SensationProjectsPage
